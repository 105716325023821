import * as React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import renderElements from "../components/elements";
import {ParallaxProvider} from "react-scroll-parallax";

export const query = graphql`
  query($id: String!) {
    wpReferenz(
        id: { eq: $id },
        ) {
        id
      slug
      title
      renderContent
    }
  }
`

const SecondPage = props => {

    let path = '/' + props.pageContext.slug;

    if (props.pageContext.isFrontPage) {
        path = '/';
    }

    if(props.data.wpReferenz === null)  return null;

    return (
        <ParallaxProvider>
            <Layout
                contentObjects={JSON.parse(props.data.wpReferenz.renderContent)}
                path={path}
            >   
                {renderElements(props.data.wpReferenz.renderContent)}
            </Layout>
        </ParallaxProvider>
    )
}

export default SecondPage